import {typedOperators,operatorTitles,operators,valueTypes,operatorDefaults} from 'o365.modules.filter.constants.ts';
import DevEx from 'o365.modules.Devex.ts';
import API from 'o365.modules.data.api.ts';

function getDefaultOperator(pType: string) {
    if (!pType) return null;
    return operatorDefaults[pType.toLowerCase()];
}
function fixNumberInFilter(pObj: any) {
    if (!pObj) return null;

    if (pObj.length) {
        for (var i = 0; i < pObj.length; i++) {
            if (pObj[i] && pObj[i].valueType === "number") {
                pObj[i].value = parseFloat(pObj[i].value);
            }
            fixNumberInFilter(pObj[i]);
        }
    }
    if (pObj.items) {
        fixNumberInFilter(pObj.items);
    }

    return pObj;
}

function mergeContainsColumns(pObj:any){
    if (!pObj) return null;

    if (pObj.length) {
        let vContainItems = [];

        for (var i = 0; i < pObj.length; i++) {
                const vFoundItem = vContainItems.find(x=>x.column == pObj[i].column && x.operator == pObj[i].operator && pObj[i].operator == "contains");
                if(!vFoundItem){
                    vContainItems.push(pObj[i]);
                }else{
                    vFoundItem.value += " "+pObj[i].value;
                }
        }
        pObj = vContainItems;

        for (var i = 0; i < pObj.length; i++) {
            mergeContainsColumns(pObj[i]);
        }

    }
    if (pObj.items) {
        pObj.items = mergeContainsColumns(pObj.items);
    }

    return pObj;
}

/**
 * @Decription - applies and filters Array<any>
 */
function applyFilterObject(pData: Array<any>, pFilter: any) {
    if (!pData) return null;
    if (!pFilter) return pData;
    return DevEx.filter(fixNumberInFilter(pFilter), pData);
}


function prettifyFilterItems(pFilterItems: any, pFields: Array<any>) {
    if (!pFilterItems) return "";
    return DevEx.toPrettyString(pFilterItems, pFields);
}
/**
 * @Description - converts FilterItem expression to localized pretty string 
 */
function prettifyFilterItem(pFilterItem: any, pField: Object) {
    if (!pFilterItem) return null;
    return DevEx.prettifyExpression(pFilterItem, pField);
}

function filterItemToString(pFilterItem: any) {
    if (!pFilterItem) return "";
    return DevEx.sqlifyExpression(pFilterItem);
}

function filterItemsToString(pFilterItems: any) {
    if (!pFilterItems) return "";
    return DevEx.toSQL(pFilterItems);
}

function groupObject(pFilterItems:any){
     if (!pFilterItems) return null;
    return DevEx.objectGroup(pFilterItems);
}

function getOperatorTitle(pOperator:string) {
    if (operatorTitles.hasOwnProperty(pOperator)) {
        return operatorTitles[pOperator];
    }

    return pOperator;
}
/**
 * @Description - gets operators by type
 */
function getOperatorsList(pType:string) {
    return typedOperators[pType].map((op: string) => {

        return {
            name: op,
            title: operatorTitles[op]
        }
    })
}

const parseValueFromServer = DevEx.parseValueFromServer;

function createInitialObject(pItem:any) {
    let vItem = createItem("or");
    vItem.items.push(createItem("and", pItem));
    return vItem;
}

function createItem(pMode:string, pItem?:any) {
    return {
        items: pItem ? [pItem] : [],
        mode: pMode ? pMode : "and",
        type: "group"
    }
}

async function filterStringToFilterItems(pString:string) {
    const vFilter =  await API.requestPost("/api/filtering/parseFilterStringToJson", JSON.stringify({
        filterString:pString
    }));
  
    return mergeContainsColumns(vFilter);
}

export function shortOperator(pOperator:string){
   
        if(pOperator.indexOf("between") > -1){
            return null;
        }
        if(pOperator.indexOf("contains") > -1){
            return null;
        }
        if(pOperator.indexOf("begin") > -1 || pOperator.indexOf("end") > -1){
            return null;
        }
        if(pOperator.indexOf("inlist") > -1 ){
            return null;
        }

        if(pOperator == "equals" || pOperator == "dateequals"){
            return "=";
        }
        if(pOperator == "notequals" || pOperator == "datenotequals"){
            return "<>";
        }
        if(pOperator.indexOf("greaterthanorequal") > -1){
            return ">=";
        }
        if(pOperator.indexOf("lessthanorequal") > -1){
            return "<=";
        }
        if(pOperator.indexOf("greaterthan") > -1){
            return ">";
        }
        if(pOperator.indexOf("lessthan") > -1){
            return "<";
        }

        return operatorTitles[pOperator];
    
}

export function shortOperatorForTemplates(pOperator:string){
   
     

        if(pOperator == "equals" || pOperator == "dateequals"){
            return "=";
        }
        if(pOperator == "notequals" || pOperator == "datenotequals"){
            return "<>";
        }
        if(pOperator.indexOf("greaterthanorequal") > -1){
            return ">=";
        }
        if(pOperator.indexOf("lessthanorequal") > -1){
            return "<=";
        }
        if(pOperator.indexOf("greaterthan") > -1){
            return ">";
        }
        if(pOperator.indexOf("lessthan") > -1){
            return "<";
        }

        return operatorTitles[pOperator];
    
}
export function parseInListNumber(pValue:any){
    return DevEx.parseInListNumber(pValue)
};


export { 
    operatorTitles, 
    operators, 
    valueTypes, 
    getDefaultOperator, 
    applyFilterObject, 
    filterItemToString, 
    filterItemsToString, 
    prettifyFilterItems, 
    prettifyFilterItem,
    getOperatorTitle,
    getOperatorsList,
    parseValueFromServer,
    createInitialObject,
    createItem,
    filterStringToFilterItems,
    groupObject
}